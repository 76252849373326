import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import Header from "../Components/Header/Header"
import Footer from "../Components/Footer/Footer"
import OfficeBanner from "../Components/ValuationSteps/ValuationStepsBanner/OfficeBanner"
import OfficeBlocks from "../Components/PropertyManagement/QuizContacts/OfficeBlocks"
import OfficeBootmForm from "../Components/PropertyManagement/QuizQuestions/OfficeBottomForm"
import OfficedetailMap from "../Components/detail-map"
import SEO from "../Components/Seo/seo"
import GooglereviewsList from "../Components/PropertyManagement/PropertyManagementGoogleReview/ReviewsListing"
import GooglereviewsList2 from "../Components/PropertyManagement/PropertyManagementGoogleReview/ReviewsListing2"
export default props => {
  const [scroll, setScroll] = useState(false)
  useEffect(() => {
    if (typeof window !== "undefined") {
      var idelement = window.location.hash
    }
    setTimeout(function () {
      var elmnt = document.getElementById(idelement.replace("#", ""))
      if (elmnt) {
        elmnt.scrollIntoView()
      }
    }, 1000)
  }, [])
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 100)
    })
  }, [])
  const GQLPage = props.data.glstrapi?.ourOffice
  const OfficeName = GQLPage?.Office_Name != "London" || "Thetford" ? true : false;

  return (
    <React.Fragment>
      <SEO title={GQLPage.Meta_Title} description={GQLPage.Meta_Description} />
      <div
        className={scroll ? "menu-fixed menu2 menu-scroll" : "menu-fixed menu2"}
      >
        <Header />
      </div>
      <div className="office-details-wrapper office-info">
        <section
          className="valuation-steps-banner office-banner office-details"
          style={{ backgroundImage: `url(${GQLPage.Office_Image.url})` }}
        >
          <div className="office-inner-wrapper">
            <OfficeBanner
              caption={GQLPage.Office_Caption}
              title={GQLPage.Office_Title}
              description={GQLPage.Office_Description}
            />
            <OfficeBlocks
              officeblocks={GQLPage.Office_Blocks}
              officename={GQLPage.Office_Name}
              OfficeEmail={GQLPage.Office_Email}
              oid={GQLPage.id}
              reviewId={GQLPage.reviewId}
            />
            { OfficeName != true &&
              <OfficeBootmForm Office_Name={GQLPage.Office_Name} />
            }
            <GooglereviewsList reviewId={GQLPage.reviewId} />
            <GooglereviewsList2 reviewId={GQLPage.reviewId} />
            {GQLPage.Latitude && GQLPage.Longitude && (
              <div className="office-detail-map" id="officesinglemap">
                <OfficedetailMap
                  Title={GQLPage.Name}
                  Latitude={GQLPage.Latitude}
                  Longitude={GQLPage.Longitude}
                  id="officedetils-map"
                  type="transport"
                />
              </div>
            )}
          </div>
          <div class="overlay-office-bg detail"></div>
        </section>
      </div>
      <div
        popularSearch="Popular_Search_Static_Common"
        className="valuation-steps-footer"
      >
        <Footer
          alias={`contact`}
          baseurl={`contact`}
          menulabel={GQLPage.Name}
          // popularSearch={"Popular_Search_Static_Common"}
          searchtype="office-details"
          officename={GQLPage.Office_Name}
        />
      </div>
    </React.Fragment>
  )
}

export const pageQuery = graphql`
  query OfficeQuery($id: ID!) {
    glstrapi {
      ourOffice(id: $id) {
        URL
        id
        Name
        Latitude
        Longitude
        Meta_Title
        reviewId
        Meta_Description
        Office_Location
        Office_Blocks {
          Block_CTA_Label
          Block_Description
          Block_Icon_Class
          Block_Title
        }
        Office_Image {
          url
        }
        Office_Caption
        Office_Description
        Office_Title
        Office_Name
        Office_Email
      }
    }
  }
`
