import React, { useEffect, useState, useRef } from "react"
import { Link } from "@StarberryUtils"
import ScrollAnimation from "react-animate-on-scroll"
import {
  Button,
  Navbar,
  Nav,
  Form,
  Container,
  FormControl,
  Row,
  Col,
  dark,
} from "react-bootstrap"
import "./QuizContacts.scss"
import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"
import ReactMarkdown from "react-markdown/with-html"
import $ from "jquery"
import { isMobile } from "react-device-detect"
import axios from "axios"
import wordsToNumbers from "words-to-numbers"
import logoBlack from "../../../images/loading-buffering.gif"
import GoogleReviewImg from "../../../images/google-logo.png"
const QuizQuestions = props => {
  const [testimonials, setTestimonials] = useState([])
  const strapiconfig = {
    headers: {
        Authorization:
            `Bearer ${process.env.GATSBY_STRAPI_FORM_TOKEN}`,
    },
  }
  const getitems = async url => {
    try {
      const { data } = await axios.get(url, strapiconfig) // could be from env files
      setTestimonials(
        data?.filter(list => list.name.toLowerCase().includes(props.reviewId))
      )
      setloading(false)
    } catch (error) {
      // cache it if fail/error;
      console.error(error)
      setloading(false)
    }
  }
  useEffect(() => {
    setloading(true)
    let url = `${process.env.GATSBY_STRAPI_SRC}/google-review-reviews`
    getitems(url)
  }, [])
  const [loading, setloading] = useState(false)
  var testimonial_count = testimonials ? testimonials.length : 0
  var rating_count = 0
  testimonials &&
    testimonials.length > 0 &&
    testimonials.map((item, ind) => {
      rating_count += parseInt(wordsToNumbers(item.starRating))
    })
  var rating_avg = rating_count / testimonial_count
  function gotohash(event) {
    var thishash = event
    $("html, body").animate(
      {
        scrollTop: $(thishash).offset().top - 120,
      },
      1000
    )
  }
  return (
    <React.Fragment>
      <section className="quiz-contacts office-action-blocks">
        <Container>
          <Row>
            {props.officeblocks.map(officeblock => {
              return (
                <>
                  <Col md={6} lg={6} xl={3}>
                    {officeblock.Block_Title !== "Live Chat" && (
                      <ScrollAnimation
                        animateOnce={true}
                        duration={0.5}
                        animateIn="fadeInUp"
                      >
                        <div className="contact-blocks">
                          <div className="contact-block-inner">
                            <div className="contact-head">
                              <i
                                className={`icon-${officeblock.Block_Icon_Class}-mobile d-xl-none`}
                              ></i>{" "}
                              <i
                                className={`icon-${officeblock.Block_Icon_Class} d-none d-xl-block`}
                              ></i>
                              {officeblock.Block_Title}
                            </div>
                            <div className="block-description">
                              <ReactMarkdown
                                source={officeblock.Block_Description}
                                escapeHtml={false}
                              />
                            </div>
                            {officeblock.Block_Icon_Class == "phone" ? (
                              <a
                                href={`tel:${officeblock.Block_CTA_Label}`}
                                className="btn btn-outline"
                              >
                                {officeblock.Block_CTA_Label}
                              </a>
                            ) : officeblock.Block_Icon_Class == "email" ? (
                              <a
                                href={`mailto:${props.OfficeEmail}`}
                                className="btn btn-outline"
                              >
                                {officeblock.Block_CTA_Label}
                              </a>
                            ) : officeblock.Block_Icon_Class == "map-marker" ? (
                              <a
                                onClick={() => gotohash("#officesinglemap")}
                                href="javascript:;"
                                className="btn btn-outline"
                              >
                                {officeblock.Block_CTA_Label}
                              </a>
                            ) : (
                              <Link href="#" className="btn btn-outline">
                                {officeblock.Block_CTA_Label}
                              </Link>
                            )}
                          </div>
                        </div>
                      </ScrollAnimation>
                    )}
                  </Col>
                </>
              )
            })}
          </Row>
          <div className="google-rating-wrap">
            <img src={GoogleReviewImg} alt="Reviews - Location Location" />
            {testimonials && testimonials.length > 0 && (
              <div className="google-inner">
                <span>
                  <strong>
                    {isMobile && parseFloat(rating_avg).toFixed(3) === "4.848"
                      ? "4.9/5 Rating"
                      : isMobile &&
                        parseFloat(rating_avg).toFixed(3) !== "4.848"
                      ? parseFloat(rating_avg).toFixed(1) + "/5 Rating"
                      : !isMobile &&
                        parseFloat(rating_avg).toFixed(3) === "4.848"
                      ? "Rated 4.9" +
                        "/5 from " +
                        testimonial_count +
                        " Customer Reviews"
                      : "Rated " +
                        parseFloat(rating_avg).toFixed(1) +
                        "/5 from " +
                        testimonial_count +
                        " Customer Reviews"}
                  </strong>
                </span>
                <span>
                  {isMobile ? (
                    "from " + testimonial_count + " Reviews"
                  ) : (
                    <span className="yellow-with-font-small">Move Happy</span>
                  )}
                </span>
              </div>
            )}
          </div>
        </Container>
      </section>
    </React.Fragment>
  )
}

export default QuizQuestions
