import { Link } from "gatsby";
import React, { useEffect, useState, useRef } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import {Button, Navbar, Nav, Form, Container, FormControl, Row, Col, dark,} from "react-bootstrap";
import "./QuizQuestions.scss";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import QuizForm from './OfficeFormrender';

const QuizQuestions = (props) => {
  
  return (
    <React.Fragment>
      <section className="quiz-questions">
        <Container>
        
          <Row className="d-md-flex">
          
            <Col lg={5} md={12}>
            <ScrollAnimation animateOnce={true} duration={0.5} offset={0} animateIn="fadeInLeft">
              <span className="small-heading">
              Email Us 
              </span>
              <h2>Don’t be shy, we don’t bite.</h2>
              <h3>Let’s talk.</h3>
              </ScrollAnimation>
            </Col>

            <Col lg={7} md={12}>
            <ScrollAnimation animateOnce={true} duration={0.5} offset={0} animateIn="fadeInRight"> 
            <div className="quiz-form">
              <QuizForm Office_Name={props.Office_Name} />
            </div>
            </ScrollAnimation>
            </Col>
            
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default QuizQuestions;
